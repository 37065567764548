import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DataTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'contacted',
  'gallery',
  'discount',
  'featureImage',
  'follow',
  'views',
  'googleDescription',
  'featuredProduct',
  'keywords',
  'content',
  'gallery',
  'categoryPath',
  'fullUrl',
  'url',
  'comments',
  'attributes',
  'featuredAd',
  'featuredAdUntil',
  'user',
  'declaration',
  'superCena',
  'specification',
  'content',
  'highlighted',
  'galleryEnergy',
  'energyImage',
  'uploadPDF',
  'typeAndName',
  'createdAt',
  'updatedAt',
  'title',
  'connectedTv',
  'dateFrom',
  'dateTo',
];

const Data = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/data`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Proizvod izbrisan..',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data');
    } catch (err) {
      notification.error({
        message: 'Greška prilikom brisanja. Molim Vas, pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys.splice(0, 0, 'title');
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      const recommended = item.recommended;
      const specialDiscount = item.specialDiscount;
      const isAction = item.IsAction;
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return {
        ...item,
        recommended: recommended ? 'DA' : 'NE',
        specialDiscount: specialDiscount ? 'DA' : 'NE',
        IsAction: isAction ? 'DA' : 'NE',
      };
    });
  }
  console.log('data', data);
  console.log('columnKeys', columnKeys);
  console.log('tableData', tableData);

  return (
    <div className='table data-table'>
      <div className='actions-block'>
        <Link to='/admin/new-data'>
          <Button type='primary'>Novi Podaci</Button>
        </Link>
      </div>

      <div style={{ textAlign: 'center' }}>
        {console.log('data', data)}
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={columnKeys}
            title='Data'
            editPath='/admin/edit-data/'
          />
        )}
        {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Data;
