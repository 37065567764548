import React from 'react';

const Home = () => {
  return (
    <div className='table'>
      <div className='actions-block'></div>
      <h2 className='special-title'>Dobrodošli u NajCena Dashboard!</h2>
    </div>
  );
};

export default Home;
