import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Data from '../pages/data/Data';
import EditData from '../pages/data/EditData';
import Categories from '../pages/data/Categories';
import Attributes from '../pages/data/Attributes';
import Gallery from '../pages/gallery/Gallery';
import Banners from '../pages/banners/Banners';
import GalleryNewContent from '../pages/gallery/GalleryNewContent';
import BannersNewContent from '../pages/banners/BannersNewContent';
import Post from '../pages/post/Post';
import Newsletter from '../pages/newsletter/Newsletter';
import EditPost from '../pages/post/EditPost';
import Tag from '../pages/tags/Tag';
import EditTag from '../pages/tags/EditTag';
import Declaration from '../pages/declaration/Declaration';
import EditDeclaration from '../pages/declaration/EditDeclaration';
// import Languages from '../pages/languages/Languages';
import DraftPost from '../pages/post/DraftPost';
import ImportData from '../pages/importData/ImportData';
import Pages from '../pages/page/Page';
import EditPages from '../pages/page/EditPage';
import Invoices from '../pages/invoices/Invoice';
import Orders from '../pages/orders/Orders';
import EditOrder from '../pages/orders/EditOrders';
import Refunds from '../pages/refund/Refunds';
import EditRefund from '../pages/refund/EditRefund';

const routes = [
  {
    label: 'Korisnici',
    children: [
      {
        label: 'Svi korisnici',
        path: '/admin/users',
        component: Users,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi korisnik',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Izmeni korisnika',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Pregled',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Galerija',
    allowed: ['admin'],
    children: [
      {
        label: 'Galerija',
        path: '/admin/gallery',
        component: Gallery,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi sadržaj',
        path: '/admin/gallery/new-content',
        component: GalleryNewContent,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Baneri',
    allowed: ['admin'],
    children: [
      {
        label: 'Baneri',
        path: '/admin/Banners',
        component: Banners,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi baneri',
        path: '/admin/banners/new-content',
        component: BannersNewContent,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Proizvodi',
    children: [
      {
        label: 'Svi proizvodi',
        path: '/admin/data',
        component: Data,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi proizvodi',
        path: '/admin/new-data',
        component: EditData,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni podatke',
        path: '/admin/edit-data/:dataId',
        component: EditData,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Kategorije',
        path: '/admin/data-categories',
        component: Categories,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Dodaj/Izmeni kategoriju',
        path: '/admin/data-categories/:categoryId',
        component: Categories,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Atributi',
        path: '/admin/data-attributes',
        component: Attributes,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  // {
  //   label: 'Objave',
  //   children: [
  //     {
  //       label: 'Sve objave',
  //       path: '/admin/posts',
  //       component: Post,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Nova objava',
  //       path: '/admin/new-post',
  //       component: EditPost,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Izmeni objavu',
  //       path: '/admin/edit-post/:postId',
  //       component: EditPost,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Sve nacrte objava',
  //       path: '/admin/draft-post/:postId',
  //       component: DraftPost,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Sve oznake',
  //       path: '/admin/tags',
  //       component: Tag,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Novi tag',
  //       path: '/admin/new-tag',
  //       component: EditTag,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Izmeni tag',
  //       path: '/admin/edit-tag/:tagId',
  //       component: EditTag,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  {
    label: 'Newsletter',
    children: [
      {
        label: 'Svi mejlovi',
        path: '/admin/newsletter',
        component: Newsletter,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  // {
  //   label: 'Stranice',
  //   children: [
  //     {
  //       label: 'Sve stranice',
  //       path: '/admin/Pages',
  //       component: Pages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Nova stranica',
  //       path: '/admin/new-page',
  //       component: EditPages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Izmeni stranicu',
  //       path: '/admin/edit-page/:id',
  //       component: EditPages,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  {
    label: 'Narudžbine',
    children: [
      {
        label: 'Sve narudžbine',
        path: '/admin/orders',
        component: Orders,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni narudžbinu',
        path: '/admin/edit-order/:orderId',
        component: EditOrder,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Fakture',
    children: [
      {
        label: 'Sve fakture',
        path: '/admin/invoices',
        component: Invoices,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Povraćaji',
    children: [
      {
        label: 'Svi povraćaji',
        path: '/admin/refunds',
        component: Refunds,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni povraćaj',
        path: '/admin/edit-refunds/:refundId',
        component: EditRefund,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Deklaracije',
    children: [
      {
        label: 'Sve deklaracije',
        path: '/admin/declarations',
        component: Declaration,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Nova deklaracija',
        path: '/admin/new-declaration',
        component: EditDeclaration,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Izmeni deklaraciju',
        path: '/admin/edit-declaration/:declarationId',
        component: EditDeclaration,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Uvoz podataka',
    children: [
      {
        label: 'Uvoz podataka',
        path: '/admin/ImportData',
        component: ImportData,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  // {
  //   label: 'My profile',
  //   children: [
  //     {
  //       label: 'View',
  //       path: '/admin/view-user/:id',
  //       component: ViewUser,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },

  // {
  //   label: 'Languages',
  //   icon: TranslationOutlined,
  //   children: [
  //     {
  //       label: 'Languages',
  //       path: '/admin/languages',
  //       component: Languages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
];

export default routes;
