import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import DeclarationForm from '../../components/forms/DeclarationForm';

const EditDeclaration = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [declaration, fetchDeclaration] = useAxios('', {}, currentuser.data.token, 'get');
  const { declarationId } = props.match.params;

  useEffect(() => {
    if (declarationId) fetchDeclaration(`${SERVER_URL}/declarations/${declarationId}`, {});
  }, [fetchDeclaration, declarationId]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/declarations` : `${SERVER_URL}/declarations/${declarationId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Declaration updated.`,
        placement: 'bottomRight',
      });
      history.push('/admin/declarations');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !declaration.isLoading && declaration.data;

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/declarations'>
          <Button type='primary'>Sve deklaracije</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}
        {declarationId && !declaration.isError && declaration.data && isDataFetched && (
          <DeclarationForm
            isNew={false}
            data={declaration.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            token={currentuser.data.token}
          />
        )}
        {!declarationId && isDataFetched && (
          <DeclarationForm
            isNew={true}
            language={currentuser.language}
            onSubmit={onSubmit}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditDeclaration;
