import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../../config';
import React from 'react';

export const ImportExcel = ({ token, uploadData }) => {
  return (
    <Upload
      action={`${SERVER_URL}/uploaddata`}
      accept='.xlsx, .csv'
      name='excelData'
      headers={{
        fileName: 'excelFile',
        Authorization: token,
        path: 'static/excel',
      }}
      fileList={[]}
      // defaultFileList={[...fileList]}
      //onPreview={this.imageHandlePreview}
      onChange={(val) => uploadData(val)}
    >
      <Button
        title='Uvezi Excel listu'
        style={{ marginLeft: '1rem' }}
        type='primary'
        icon={<UploadOutlined style={{ marginRight: '5px' }} />}
      >
        Uvezi Excel
      </Button>
    </Upload>
  );
};
