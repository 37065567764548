import React, { useEffect, useState } from 'react';
import { Tabs, Divider, Input, Select, Button, Form } from 'antd';
import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import JoditEditor from 'jodit-react';

let formInit = {
  name: '',
  SEOTitle: '',
  SEODescription: '',
  SEOKeywords: '',
  content: '',
  image: undefined,
  loading: false,
  attributes: [],
};

const configJodit = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: '300',
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    // 'font',
    'fontsize',
    '\n',
    'brush',
    'paragraph',
    'link',
    'align',
    // '|',
    'undo',
    'redo',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
    'fullsize',
    'print',
    'preview',
    'find',
    'table',
  ],
};

const CategoryForm = ({ onSubmit, category, token, language, setEdit, SERVER_URL }) => {
  const [form] = Form.useForm();
  const [tab, setTab] = useState();
  const [attributes, fetchAttributes] = useAxios('', [], token, 'get');
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete category[key]);

  let initialValues = { ...formInit, ...category };

  useEffect(() => {
    fetchAttributes(`${SERVER_URL}/attributes`, []);
    if (language && language.selected) setTab(language.selected.code);
    form.resetFields();
  }, [fetchAttributes, language, SERVER_URL, category, form /*, categoryImage */]);

  useEffect(() => {
    if (category && category.image) {
      setImage(category.image.url);
    }
  }, [category, form]);

  const onFinish = async (values, isNew) => {
    console.log('valuessss', values);
    onSubmit(values, isNew);
  };

  const imageHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'image' });
  };

  const deleteImageHandler = () => {
    setImage(null);
    form.setFieldsValue({ image: null });
  };

  const onInsertIMage = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  return (
    <div className='panel panel-primary'>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <span>{!category.name ? 'DODAJ' : 'IZMENI'}</span>
          <button className='btn-close' onClick={() => setEdit({ visible: false, category: {} })}>
            <span className='icon'>&#10005;</span>
          </button>
        </h4>
      </div>

      <div className='panel-body'>
        {attributes.isLoading && <h2 style={{ textAlign: 'center' }}>UČITAVANJE</h2>}
        <Form
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !category.name)}
          layout='vertical'
          form={form}
        >
          <h3 style={{ marginBottom: '30px' }}>
            <span>{!category.name ? 'Dodaj' : 'Izmeni'}</span> kategoriju
          </h3>{' '}
          <Form.Item
            label='Naziv'
            rules={[
              {
                required: true,
                message: 'Please enter category name!',
              },
            ]}
            name={['name']}
          >
            <Input />
          </Form.Item>
          <Form.Item label='SEO Naslov' name={['SEOTitle']}>
            <Input />
          </Form.Item>
          <Form.Item label='SEO Opis' name={['SEODescription']}>
            <Input />
          </Form.Item>
          <Form.Item label='SEO Ključne reči' name={['SEOKeywords']}>
            <Input />
          </Form.Item>
          <Form.Item label='Opis kategorije za shop' name='content'>
            <JoditEditor
              name='content'
              style={{ margin: '2px 0px', height: '550px !important' }}
              config={configJodit}
            />
          </Form.Item>
          <Divider type='horizontal' />
          <Form.Item label='Atributi' name='attributes'>
            <Select mode='multiple'>
              {!attributes.isLoading &&
                attributes.data &&
                attributes.data.items &&
                attributes.data.items.length > 0 &&
                attributes.data.items.map((attr) => (
                  <Select.Option value={attr._id} key={attr._id}>
                    {console.log('ATTRRRR', attr)}
                    {attr?.name.sr}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label='Fotografija' name='image' valuePropName='image'>
            <UploadBox
              editHandler={imageHandler}
              deleteHandler={deleteImageHandler}
              image={image}
              index={0}
              name='image'
            />
          </Form.Item>
          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              Potvrdi
            </Button>
          </div>
        </Form>

        {modal.visible && (
          <GalleryModal
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/categories/'
            imageType='category'
            imageHeight={1000}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ ...modal, visible: false })}
            onInsert={(values) => onInsertIMage(values, modal.formKey)}
          />
        )}
      </div>
    </div>
  );
};

export default CategoryForm;
