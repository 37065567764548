import React, { useContext } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';

const DataTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath }) => {
  const user = useContext(UserContext);
  let searchInput;
  const mapStatusToTranslation = (status) => {
    const translationMap = {
      ACTIVE: 'Aktivan',
      SUSPENDED: 'Suspendovan',
    };

    return translationMap[status] || status; // Return the translated value if available, or the original status
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      console.log('dataIndex', dataIndex);
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraži ${Array.isArray(dataIndex) ? dataIndex[0] : dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button type='secondary' onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]]
        : record[dataIndex];
      if (!filterRecord || !value || value === null) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys
    .filter((item) => item !== 'lan')
    .map((item) => ({
      key: item,
      title:
        item === 'title'
          ? 'Naziv'
          : item === 'price'
          ? 'Cena'
          : item === 'discountPrice'
          ? 'Cena sa popustom'
          : item === 'specialDiscount'
          ? 'Posebni popust'
          : item === 'recommended'
          ? 'Preporučeno'
          : item === 'IsAction'
          ? 'Na akciji'
          : item === 'lan'
          ? 'Jezik'
          : item === 'status'
          ? 'Status'
          : item === 'typeAndName'
          ? 'Tip i ime'
          : item === 'code'
          ? 'Kod'
          : item === 'stock'
          ? 'Na stanju'
          : item === 'category'
          ? 'Kategorija'
          : item === 'createdAt'
          ? 'Kreirano u'
          : item === 'updatedAt'
          ? 'Izmenjeno u'
          : item,
      dataIndex: item === 'createdBy' ? 'user' : ['title', 'category'].includes(item) ? item === 'title' : item,
      ...getColumnSearchProps(
        item === 'createdBy'
          ? 'user'
          : ['name', 'category'].includes(item)
          ? item === 'name'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
      ),
      render: (text, record) => {
        if (item === 'title') {
          return record.title;
        }
        if (item === 'price') {
          return record.price;
        }
        if (item === 'discountPrice') {
          return record.discountPrice;
        }
        // if (item === 'specialDiscount') {
        //   return record.specialDiscount ? 'DA' : 'NE';
        // }
        // if (item === 'recommended') {
        //   return record.recommended ? 'DA' : 'NE';
        // }
        // if (item === 'IsAction') {
        //   return record.recommended ? 'DA' : 'NE';
        // }
        if (item === 'status') {
          const translatedStatus = mapStatusToTranslation(record.status);
          return translatedStatus;
        }
        if (item === 'typeAndName') {
          return record.typeAndName;
        }
        if (item === 'code') {
          return record.code;
        }
        if (item === 'stock') {
          return record.stock;
        }
        if (item === 'category') {
          return record?.category?.name;
        }
        if (item === 'createdAt') {
          return record.createdAt;
        }
        if (item === 'updatedAt') {
          return record.updatedAt;
        }
        return text;
      },
    }));

  const mapStockToTranslation = (stock) => {
    const stockTranslations = {
      InStock: 'Na stanju 1-3 dana',
      Order: 'Poručivanje 7-15 dana',
      OutOfStock: 'Nema na stanju',
    };
    return stockTranslations[stock] || 'Nepoznato'; // Return 'Nepoznato' for unknown stock values
  };

  console.log('someData', data);
  const translatedData = data.map((item) => ({
    ...item,
    stock: mapStockToTranslation(item.stock),
  }));

  console.log('columns', columns);

  columns.push({
    title: 'Akcija',
    //width: '10vw',    /*bilo je bez width,ali se tada ne poravnaju linije header i body*/
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {console.log(editPath)}
        {console.log('edit:', editPath)}
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Link to={editPath + record._id}>
            <EditOutlined
              className='icon-unlock'
              title={`Edit ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
            <EditFilled
              className='icon-lock'
              title={`Edit ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </Link>
        </div>

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={viewPath + record._id}>
              <EyeOutlined
                className='icon-unlock'
                title={`View ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EyeOutlined
                className='icon-lock'
                title={`View ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        )}

        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title='Ovo će obrisati proizvod'
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText='OK'
            cancelText='Odustani'
            cancelButtonProps={{ type: 'secondary' }}
            okButtonProps={{ type: 'primary' }}
          >
            <DeleteOutlined className='icon-unlock' title={`Delete ${title.toLowerCase()}`} />
            <DeleteFilled className='icon-lock' title={`Delete ${title.toLowerCase()}`} />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  return (
    <div className='table-data-okvir'>
      <Table
        size='middle'
        bordered
        dataSource={translatedData}
        columns={columns}
        rowKey='_id'
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        pagination={{
          defaultPageSize: 20,
          position: ['topCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
        scroll={{ y: '88vh' }} // 74vh
      />
    </div>
  );
};

export default DataTable;
