import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/InvoiceTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'content', 'orderId', 'userEmail', 'user', 'userId'];

const Invoices = () => {
  const currentuser = useContext(UserContext);
  const [invoices, fetchInvoices] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchInvoices(`${SERVER_URL}/invoices`, []);
  }, [fetchInvoices]);

  const deleteOrderHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/invoices/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/invoices');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };
  let columnKeys;
  if (invoices.data && invoices.data.items && invoices.data.items.length > 0) {
    const keys = Object.keys(invoices.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys.splice(1, 0, 'userEmail');
  }

  let tableData = invoices.data.items;
  if (invoices.data && invoices.data.items && invoices.data.items.length > 0) {
    tableData = invoices.data.items.map((item) => {
      item.userEmail = item.userId ? item.userId.email : '';
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      item.dateOfPayment = new Date(item.dateOfPayment).toLocaleString();
      return item;
    });
  }

  const openPdf = async (path) => {
    try {
      const resPdF = await Axios.get(`${SERVER_URL}/invoices/pdf?path=${path}`, {
        withCredentials: false,
        responseType: 'arraybuffer',
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const blob = new Blob([resPdF.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='table invoices'>
      <div className='actions-block'></div>

      <div style={{ textAlign: 'center' }}>
        {invoices.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!invoices.isLoading && invoices.data && invoices.data.items && invoices.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteOrderHandler}
            columnKeys={columnKeys}
            title='Invoices'
            openPdf={openPdf}
          />
        )}
        {!invoices.isLoading && invoices.data && invoices.data.items && invoices.data.items.length === 0 && (
          <div className='no-data-box'>
            <h2>Nema podataka</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Invoices;
