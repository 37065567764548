import React, { useState, useEffect } from 'react';
import { Tabs, Divider, Input, Select, Button, Form, Checkbox } from 'antd';

const ATTR_TYPES = ['CHOICE', 'MULTICHOICE', 'INPUT', 'NUMBER', 'CHECKBOX' /* , 'DATE' */];
const ATTR_TYPES_TRANSLATIONS = {
  CHOICE: 'Izbor',
  MULTICHOICE: 'Višestruki izbor',
  INPUT: 'Unos',
  NUMBER: 'Broj',
  CHECKBOX: 'Polje za potvrdu',
};

const formInit = {
  name: {},
  type: '',
  unit: '',
  isRequired: false,
  isGlobal: false,
  includeInSearch: false,
};

const AttributeForm = ({ setEdit, attribute, language, onSubmit }) => {
  const [form] = Form.useForm();
  const [tab, setTab] = useState(language.selected.code);
  const editAttr = { ...attribute };
  [('createdAt', 'updatedAt', '__v', 'values')].forEach((key) => delete editAttr[key]);
  let initialValues = { ...formInit, ...editAttr };

  useEffect(() => {
    form.resetFields();
  }, [attribute, form]);

  return (
    <div className='panel panel-primary'>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <span>{`${editAttr._id ? 'IZMENI' : 'DODAJ'}`} ATRIBUT</span>

          <button className='btn-close' onClick={() => setEdit({ visible: false, attribute: {} })}>
            <span className='icon'>&#10005;</span>
          </button>
        </h4>
      </div>
      <div className='panel-body'>
        <Form
          initialValues={initialValues}
          onFinish={(values) => onSubmit(values, !attribute.name)}
          layout='vertical'
          form={form}
        >
          <div style={{ color: 'white' }}>{tab} language</div>

          <Form.Item
            label='Naziv'
            rules={[
              {
                required: true,
                message: 'Molim vas, unesite naziv atributa!',
              },
            ]}
            name={['name', tab]}
          >
            <Input />
          </Form.Item>
          <Form.Item label='Tip' name='type' rules={[{ required: true, message: 'Molim Vas, izaberite atribut!' }]}>
            <Select>
              {ATTR_TYPES.map((type) => (
                <Select.Option value={type} key={type}>
                  {ATTR_TYPES_TRANSLATIONS[type]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label='Jedinica' name='unit'>
            <Input />
          </Form.Item>
          <Form.Item label='Obavezno' name='isRequired' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <Form.Item label='Globalni atribut' name='isGlobal' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <Form.Item label='Uključi u pretragu' name='includeInSearch' valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              Potvrdi
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AttributeForm;
