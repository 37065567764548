import React from 'react';

const UserLog = ({ data, updateHandler, csv }) => {
  return (
    <div className='dashboard'>
      <Card title={'Broj prijavljivanja u odabranom intervalu: ' + logcount} bordered={false}>
        <div className='log-graph'>
          <RangePicker
            value={chartrange}
            allowClear
            size='large'
            format={dateFormat}
            onChange={(value) => handleRange(value)}
          />

          <ResponsiveContainer width='100%' aspect={16.0 / 5.0}>
            <LineChart
              data={arr}
              margin={{
                top: 24,
                left: -24,
                right: 0,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='_id'
                tickFormatter={formatXAxis}
                axisLine={{ stroke: 'lightslategray', strokeWidth: 2 }}
              />
              <YAxis axisLine={{ stroke: 'lightslategray', strokeWidth: 2 }} />
              <Tooltip />
              <Line type='basis' dataKey='count' strokeWidth={1} stroke='lightslategray' activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Card>

      <Card title='Period prijavljivanja za izvezivanje' bordered={false} style={{ marginTop: 16 }}>
        <RangePicker value={range} size='large' format={dateFormat} onChange={(value) => onFinish(value)} />

        <ExportExcel
          csvData={csv != null && csv.length >= 1 ? csv : []}
          fileName={`logs ` + dayjs(new Date()).format('DD:MM:YYYY HH:MM')} // Ime xlsx fajla
          propsToRemove={['_id', 'objectId', 'userId', 'url', 'description', 'updatedAt', '__v']} // Opcija 1 - uklanjaju se polja koje ne treba da se exportuju
          // format="products" // Opcija 2 - custom formatiranje - treba po imenu format polja u exportExcel.js formatirati polja po zelji
        />
      </Card>

      <Card title='Informacije o prijavljivanjima' bordered={false} style={{ marginTop: 16 }}>
        <Table
          showHeader={false}
          columns={columns}
          expandedRowRender={expandedRowRender}
          dataSource={dataTable}
          className='components-table-demo-nested'
        />
      </Card>
    </div>
  );
};

export default UserLog;
