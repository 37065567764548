import React, { useEffect, useState } from 'react';
// import slugify from 'slugify';
import { getTreeFromFlatData } from 'react-sortable-tree';
import JoditEditor from 'jodit-react';
import {
  Tabs,
  Divider,
  Input,
  Checkbox,
  Modal,
  Select,
  /* DatePicker, */ Button,
  Form,
  Row,
  Col,
  TreeSelect,
  DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';
import DNDGallery from '../base/DNDGallery';
import { GalleryModal } from './';
import UploadDocument from './UploadDocument';
import slugify from 'slugify';
import moment from 'moment';

const { Option } = Select;

/**
 * @constant {Integer} GALLERY_LIMIT - Max number of images in gallery
 */
const GALLERY_LIMIT = 5;

const configJodit = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: '300',
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    // 'font',
    'fontsize',
    '\n',
    'brush',
    'paragraph',
    'link',
    'align',
    // '|',
    'undo',
    'redo',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
    'fullsize',
    'print',
    'preview',
    'find',
    'table',
  ],
};

const formInit = {
  _id: null,
  title: '',
  googleDescription: '',
  keywords: '',
  content: '',
  // url: '',
  code: null,
  price: 0,
  stock: '',
  discount: false,
  specialDiscount: false,
  highlighted: false,
  recommended: false,
  discountPrice: 0,
  fullUrl: '',
  featuredAd: false,
  featuredAdUntil: '',
  status: 'ACTIVE',
  category: '',
  categoryPath: '',
  attributes: [],
  featureImage: undefined,
  energyImage: [],
  gallery: [],
  uploadPDF: '',
  declaration: undefined,
  connectedTv: [],
};

const serbianTranslations = {
  InStock: 'Na stanju 1 - 3 dana',
  Order: 'Poručivanje 7 - 15 dana',
  OutOfStock: 'Nije na stanju',
};

const stockOptions = ['InStock', 'Order', 'OutOfStock'];

const mapOptionToTranslation = (option) => {
  const translationMap = {
    Aktivan: 'ACTIVE',
    Suspendovan: 'SUSPENDED',
    // Add more translations here if needed
  };

  return translationMap[option] || option; // Return the translated value if available, or the original option
};

const DataFrom = ({ isNew, data, categoryData, categories, language, onSubmit, SERVER_URL, token, declarations }) => {
  const [form] = Form.useForm();
  const [attributes, fetchAttributes] = useAxios('', [], token, 'get');
  const [secondTypeAttributes, fetchSecondTypeAttributes] = useAxios('', [], token, 'get');
  const [globalAttributes, fetchGlobalAttributes] = useAxios('', [], token, 'get');
  const [productsByCategory, fetchProductsByCategory] = useAxios('', [], token, 'get');
  const [categoryAttributes, setCategoryAttributes] = useState([]);
  const [categoryAttributesSecond, setCategoryAttributesSecond] = useState([]);
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [pdfModal, setPdfModal] = useState(false);
  const [selectedPDF, setSelectedPDF] = useState();
  const [rerender, setReRender] = useState(false);
  const [attachments, setAttachments] = useState();
  const [currentCategoryName, setCurrentCategoryName] = useState('');
  const [initialValues, setInitialValues] = useState({ ...formInit, ...data });

  const getCategoryPath = (categoryId, categories) => {
    const category = categories?.find((cat) => cat?._id === categoryId);

    setCurrentCategoryName(category?.name);

    return category ? category?.path : '';
  };

  if (data && data.dateFrom && data.dateTo) {
    data.dateFrom = data.dateFrom && moment(data.dateFrom);
    data.dateTo = data.dateTo && moment(data.dateTo);
  }

  if (data) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete data[key]);

  useEffect(() => {
    const updatedAttributes = data?.attributes?.map((attr) => {
      const matchingNewItem = secondTypeAttributes?.data?.items?.find((item) => item._id === attr.attributeId);
      if (matchingNewItem && matchingNewItem.selectedValues) {
        return {
          ...attr,
          values: matchingNewItem.selectedValues.map((val) => val._id),
        };
      }
      return attr;
    });

    const correctOrder = secondTypeAttributes?.data?.items?.map((item) => item._id);

    const reorderedAttributes = correctOrder?.map((id) => {
      return updatedAttributes.find((attr) => attr?.attributeId === id) || { values: [], attributeId: id };
    });

    form.setFieldsValue({ attributes: reorderedAttributes || [] });
  }, [data, fetchSecondTypeAttributes, secondTypeAttributes]);

  const [energyImage, setImageEnergy] = useState(
    initialValues.energyImage?.length > 0 ? initialValues.energyImage : [],
  );
  const [image, setImage] = useState(initialValues.featureImage ? initialValues.featureImage.url : undefined);
  const [currentGallery, setCurrentGallery] = useState(initialValues.gallery?.length > 0 ? initialValues.gallery : []);

  useEffect(() => {
    if (data && data?.uploadPDF) {
      setAttachments(data?.uploadPDF);
    }
  }, [data?.uploadPDF]);

  useEffect(() => {
    if (currentCategoryName?.length > 0) {
      fetchProductsByCategory(
        `${SERVER_URL}/data-by-category-url-dashboard/${slugify(currentCategoryName, {
          lower: true,
          strict: true,
        })}`,
        [],
      );
    }
  }, [fetchProductsByCategory, SERVER_URL, form, currentCategoryName]);

  useEffect(() => {
    fetchGlobalAttributes(`${SERVER_URL}/attributes?filter={"isGlobal":true}`, []);
    if (data) {
      if (data?.featuredAdUntil) form.setFieldsValue({ featuredAdUntil: dayjs(data.featuredAdUntil) });
      if (data?.category) {
        const categoryPath = getCategoryPath(data?.category, categories);

        fetchAttributes(`${SERVER_URL}/attributes/category-dashboard/${categoryPath}`, []);
        fetchSecondTypeAttributes(`${SERVER_URL}/attributes/get-by-product/${data?._id}`, []);
      }
      if (data.attributes && data.attributes.length > 0) {
        data.attributes.forEach((d) => {
          if (d && d.dateValue) {
            d.dateValue = dayjs(d.dateValue);
          }
        });
      }
    }
  }, [data, categories, form, SERVER_URL, fetchAttributes, fetchGlobalAttributes]);

  useEffect(() => {
    if (globalAttributes.data.items && attributes.data) {
      setCategoryAttributes([...attributes.data]);
    }
  }, [attributes, globalAttributes]);

  useEffect(() => {
    setSelectedPDF({ name: data?.uploadPDF });
  }, [data]);
  const treeData = getTreeFromFlatData({
    flatData: categories?.map((node) => ({
      title: node.name,
      value: node._id,
      key: node._id,
      parent: node.parent,
      _id: node._id,
    })),
    getKey: (node) => node._id, // resolve a node's key
    getParentKey: (node) => node.parent, // resolve a node's parent's key
    rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
  });

  const getCategoriesRecurisvely = (data) => {
    for (let i = 0; i < data.length; i++) {
      const currentObject = data[i];

      // Check if the current object has a 'title' property
      if (currentObject.title && currentObject.title) {
        currentObject.title = currentObject.title;
      }

      // Check if the current object has 'children' and it's an array
      if (currentObject.children && Array.isArray(currentObject.children)) {
        getCategoriesRecurisvely(currentObject.children); // Recursively call the function for the children array
      }
    }
    return data;
  };

  const onChangeAttribute = (index, attrId, name, value) => {
    let prevAttributes = form.getFieldValue('attributes');
    const attr = {
      attributeId: attrId,
      [name]: name === 'values' ? (!Array.isArray(value) ? [value] : value) : value,
    };
    prevAttributes[index] = attr;
    form.setFieldsValue({ attributes: prevAttributes });
  };

  const [discountChecked, setDiscountChecked] = useState(false);

  const handleDiscountChange = (e) => {
    setDiscountChecked(e.target.checked);
  };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    const goodAttachments = attachments?.filter((item) => item.status === 'done');

    const finalValues = { ...values, uploadPDF: goodAttachments };

    onSubmit(finalValues, isNew);
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedPDF(file);
    }
  };

  const handleOkClick = (e) => {
    setPdfModal(false);
  };

  /**
   * Open feature image modal
   */
  const editImageTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'featureImage' });
  };

  const editEnergyImmageTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'energyImage' });
  };

  /**
   *  Delete feature Image
   */
  const deleteFeatureImageHandler = () => {
    setImage(null);
    form.setFieldsValue({ featureImage: null });
  };

  /**
   * Open gallery modal
   */
  const editGalleryTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: GALLERY_LIMIT });
  };

  /**
   * Open energy image modal
   */
  const editEnergyImageTriger = () => {
    setModal({ ...modal, visible: true, formKey: 'energyImage', limit: 2 });
  };
  /**
   * Open gallery modal single image edit
   */
  const editGallerySingleImageTrigger = (index, id) => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: 1, index, id });
  };

  /**
   * Open gallery modal single image edit
   */
  const editSingleEnergyImageTrigger = (index, id) => {
    setModal({ ...modal, visible: true, formKey: 'energyImage', limit: 1, index, id });
  };

  /**
   * Delete image by id from the specified image type
   * @param {String} id
   * @param {String} imageType - 'gallery' or 'energyImage'
   */
  const deleteImageHandler = (id, imageType) => {
    if (imageType === 'gallery') {
      const newGallery = currentGallery.filter((image) => image._id !== id);
      setCurrentGallery(newGallery);
      form.setFieldsValue({ gallery: newGallery });
    } else if (imageType === 'energyImage') {
      const newEnergyImage = energyImage.filter((image) => image._id !== id);
      setImageEnergy(newEnergyImage);
      form.setFieldsValue({ energyImage: newEnergyImage });
    }
  };

  /**
   * Delete image in gallery by id
   * */
  const deleteGalleryImageHandler = (id) => {
    deleteImageHandler(id, 'gallery');
  };

  /**
   * Delete image in energyImage by id
   */
  const deleteEnergyImageHandler = (id) => {
    deleteImageHandler(id, 'energyImage');
  };

  /**
   * Insert image/images in form by form key
   * @param {[Object]} values
   * @param {String} formKey
   * @param {String} stateToUpdate - 'gallery' or 'energyImage'
   */
  const onInsert = (values, formKey, stateToUpdate) => {
    form.setFieldsValue({ [formKey]: values });
    if (stateToUpdate === 'gallery') {
      setCurrentGallery(values);
    } else if (stateToUpdate === 'energyImage') {
      setImageEnergy(values);
    } else if (stateToUpdate === 'featureImage') {
      setImage(values);
    }
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item
                  label='Naslov'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas, unesite naziv!',
                      message: 'Molim Vas, unesite naziv!',
                    },
                  ]}
                  name={['title']}
                  // onChange={(e) => form.setFieldsValue({ url: slugify(e.target.value, { lower: true, strict: true }) })}
                >
                  <Input />
                </Form.Item>

                <Form.Item label='Google Opis' name='googleDescription'>
                  <Input />
                </Form.Item>

                <Form.Item label='Google ključne reči' name='keywords'>
                  <Input />
                </Form.Item>

                <Form.Item label='Sadržaj' name='content'>
                  <JoditEditor
                    name='content'
                    style={{ margin: '2px 0px', height: '550px !important' }}
                    config={configJodit}
                  />
                </Form.Item>

                <Form.Item label='Specifikacija' name='specification'>
                  <JoditEditor
                    name='specification'
                    style={{ margin: '2px 0px', height: '550px !important' }}
                    config={configJodit}
                  />
                </Form.Item>

                <Form.Item label='Deklaracija' name='declaration'>
                  <Select
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {declarations &&
                      declarations?.length > 0 &&
                      declarations?.map((item, index) => (
                        <Select.Option key={index} value={item._id}>
                          {item.title}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>

                <Divider type='horizontal' />
                {/* <Form.Item label='Url' name='url'>
                  <Input disabled />
                </Form.Item> */}

                <Form.Item label='Kod' name='code'>
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  label='Status'
                  name='status'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas, izaberite status!',
                      message: 'Molim Vas, izaberite status!',
                    },
                  ]}
                >
                  <Select>
                    {['Aktivan', 'Suspendovan'].map((option, index) => (
                      <Select.Option key={`${option}_${index}`} value={mapOptionToTranslation(option)}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label='Na stanju'
                  name='stock'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas, izaberite da li je na stanju!',
                    },
                  ]}
                >
                  <Select>
                    {stockOptions.map((option, index) => (
                      <Select.Option key={`${option}_${index}`} value={option}>
                        {serbianTranslations[option]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label='Cena' name='price'>
                  <Input type='number' />
                </Form.Item>

                <Form.Item label='Početak akcije' name='dateFrom' className='inline-form-item'>
                  <DatePicker style={{ borderRadius: '20px' }} placeholder='Izaberite datum' format={'DD-MM-YYYY'} />
                </Form.Item>
                <Form.Item label='Kraj akcije' name='dateTo' className='inline-form-item'>
                  <DatePicker style={{ borderRadius: '20px' }} placeholder='Izaberite datum' format={'DD-MM-YYYY'} />
                </Form.Item>

                <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Popust' name='discount' valuePropName='checked'>
                      <Checkbox onChange={handleDiscountChange} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item label='Cena sa popustom' name='discountPrice'>
                      <Input type='number' disabled={!discountChecked} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Popularno' name='highlighted' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item label='Ponuda nedelje' name='specialDiscount' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={8}>
                    <Form.Item label='Preporučeno' name='recommended' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item label='Na akciji' name='IsAction' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Featured' name='featuredAd' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item label='Featured until' name='featuredAdUntil' getValueProps={() => {}} rules={[{ type: 'object' }]}>
                      <DatePicker style={{ width: '100%' }} size='large' rules={[{ type: 'object' }]} />
                    </Form.Item>
                  </Col>
                </Row> */}
              </div>
            </Col>

            <Col xs={24} md={8}>
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Kategorija</h4>
                </div>

                <Form.Item
                  label='Kategorija'
                  name='category'
                  className='panel-body'
                  rules={[{ required: true, message: 'Molim Vas, izaberite kategoriju!' }]}
                >
                  <TreeSelect
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    name='category'
                    treeData={getCategoriesRecurisvely(treeData)}
                    placeholder='Please select category'
                    onChange={(value) => {
                      form.setFieldsValue({ attributes: [] });
                      const categoryPath = getCategoryPath(value, categories);
                      fetchAttributes(`${SERVER_URL}/attributes/category/${categoryPath}`, []);
                    }}
                  />
                </Form.Item>
              </div>

              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Atributi</h4>
                </div>

                <div className='panel-body'>
                  <Form.List name='attributes'>
                    {(fields) =>
                      // categoryAttributes?.length > 0 &&
                      // categoryAttributes?.map((attr, ind) => {
                      secondTypeAttributes?.data?.items?.length > 0 &&
                      secondTypeAttributes?.data?.items?.map((attr, ind) => {
                        switch (attr.type) {
                          case 'MULTICHOICE':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, 'values']}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Molim Vas, izaberite ${attr.name[language.selected.code]}`,
                                    message: `Molim Vas, izaberite ${attr.name[language.selected.code]}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Select
                                  mode='multiple'
                                  onChange={(value) => onChangeAttribute(ind, attr._id, 'values', value)}
                                >
                                  {attr.values.map((val) => (
                                    <Select.Option value={val._id} key={val._id}>
                                      {val.value[language.selected.code]}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );

                          case 'CHOICE':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, 'values']}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Molim Vas, izaberite ${attr.name[language.selected.code]}`,
                                    message: `Molim Vas, izaberite ${attr.name[language.selected.code]}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Select onChange={(value) => onChangeAttribute(ind, attr._id, 'values', value)}>
                                  {attr.values.map((val) => (
                                    <Select.Option value={val._id} key={val._id}>
                                      {val.value[language.selected.code]}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );

                          case 'INPUT':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, 'inputValue']}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Molim Vas, izaberite ${attr.name[language.selected.code]}`,
                                    message: `Molim Vas, izaberite ${attr.name[language.selected.code]}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Input
                                  onChange={(e) => onChangeAttribute(ind, attr._id, 'inputValue', e.target.value)}
                                />
                              </Form.Item>
                            );

                          case 'NUMBER':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, 'numberValue']}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Molim Vas, izaberite ${attr.name[language.selected.code]}`,
                                    message: `Molim Vas, izaberite ${attr.name[language.selected.code]}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Input
                                  type='number'
                                  onChange={(e) => onChangeAttribute(ind, attr._id, 'numberValue', +e.target.value)}
                                />
                              </Form.Item>
                            );

                          case 'CHECKBOX':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, 'checkboxValue']}
                                // className='panel-body'
                                valuePropName='checked'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Molim Vas, izaberite ${attr.name[language.selected.code]}`,
                                    message: `Molim Vas, izaberite ${attr.name[language.selected.code]}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Checkbox
                                  onChange={(e) => onChangeAttribute(ind, attr._id, 'checkboxValue', e.target.checked)}
                                />
                              </Form.Item>
                            );

                          default:
                            return null;
                        }
                      })
                    }
                  </Form.List>
                </div>
              </div>

              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Istaknuta fotografija</h4>
                </div>

                <Form.Item name='featureImage' valuePropName='image'>
                  <div className='panel-body'>
                    <UploadBox
                      editHandler={editImageTrigger}
                      deleteHandler={deleteFeatureImageHandler}
                      image={image}
                      index={0}
                      name='featureImage'
                    />
                  </div>
                </Form.Item>
              </div>

              {categoryData?.name === 'Televizori' && (
                <div className='panel panel-primary'>
                  <div className='panel-heading'>
                    <h4 className='panel-title'>Uveži sa drugim televizorima</h4>
                  </div>

                  <Form.Item label='Televizori' name='connectedTv'>
                    <Select mode='multiple'>
                      {productsByCategory &&
                        productsByCategory?.data &&
                        productsByCategory?.data?.items &&
                        productsByCategory?.data?.items?.length > 0 &&
                        productsByCategory?.data?.items?.map(
                          (item) =>
                            item?._id !== data?._id && (
                              <Select.Option value={item?._id} key={item?._id}>
                                {item?.title}
                              </Select.Option>
                            ),
                        )}
                    </Select>
                  </Form.Item>
                </div>
              )}

              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Energetska efikasnost</h4>
                </div>
                <Form.Item name='energyImage' valuePropName='image'>
                  <div className='panel-body'>
                    {energyImage?.length > 0 && (
                      <DNDGallery
                        imageType='energyImage'
                        deleteGalleryImageHandler={deleteEnergyImageHandler}
                        editGallerySingleImageTrigger={editSingleEnergyImageTrigger}
                        form={form}
                        setGallery={setImageEnergy}
                      />
                    )}
                    {energyImage?.length < GALLERY_LIMIT && <UploadBox editHandler={editEnergyImageTriger} />}
                  </div>
                </Form.Item>
              </div>
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Galerija fotografija</h4>
                </div>

                <Form.Item name='gallery' valuePropName='image'>
                  <div className='panel-body'>
                    {currentGallery?.length > 0 && (
                      <DNDGallery
                        imageType='gallery'
                        deleteGalleryImageHandler={deleteGalleryImageHandler}
                        editGallerySingleImageTrigger={editGallerySingleImageTrigger}
                        form={form}
                        setGallery={setCurrentGallery}
                      />
                    )}

                    {currentGallery?.length < GALLERY_LIMIT && <UploadBox editHandler={editGalleryTrigger} />}
                  </div>
                </Form.Item>
              </div>
              {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Input disabled value={selectedPDF?.name} />
                <Button name='uploadPdf' onClick={() => setPdfModal(true)}>
                  Otpremi PDF
                </Button>
                <Modal
                  onOk={(e) => {
                    handleOkClick(e);
                  }}
                  onCancel={() => {
                    setPdfModal(false);
                  }}
                  centered
                  title='Choose a PDF'
                  open={pdfModal}
                >
                  <Form.Item>
                    <input type='file' accept='.pdf' onChange={onFileChange} />
                  </Form.Item>
                </Modal>
              </div> */}

              {/* UPLOAD DOCUMENTS */}
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Dokumenta</h4>
                </div>

                <Form.Item name='uploadPDF'>
                  <div className='panel-body'>
                    <UploadDocument attachments={attachments} setAttachments={setAttachments} />
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Dodaj' : 'Izmeni'} proizvod
            </Button>
          </div>
        </Form>

        {modal.visible && (
          <GalleryModal
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/data/'
            imageType='data'
            // imageHeight={500}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ visible: false, formKey: null, limit: 1 })}
            onInsert={(values) => onInsert(values, modal.formKey, modal.formKey)}
            imageId={modal.id}
            imageIndex={modal.index}
          />
        )}
      </div>
    </div>
  );
};

export default DataFrom;
