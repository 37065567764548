import React, { useState, useEffect } from 'react';
import { Tabs, Divider, Input, Button, Form } from 'antd';

const formInit = {
  value: {},
};

const ValueForm = ({ setEdit, attrId, language, onSubmit, value }) => {
  const [form] = Form.useForm();
  const [tab, setTab] = useState(language.selected.code);
  ['createdAt', 'updatedAt', '__v'].forEach((key) => delete value[key]);
  let initialValues = { ...formInit, ...value };

  useEffect(() => {
    form.resetFields();
  }, [value, form]);

  return (
    <div className='panel panel-primary'>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <span>Dodaj vrednost</span>
          {/* <span
            style={{ cursor: 'pointer', padding: '6px', color: 'grey' }}
            onClick={() => {
              setEdit({ visible: false, attrId: null, value: {} });
            }}
          >
            X
          </span> */}

          <button className='btn-close' onClick={() => setEdit({ visible: false, attrId: null, value: {} })}>
            <span className='icon'>&#10005;</span>
          </button>
        </h4>
      </div>
      <div className='panel-body'>
        <Form
          layout='vertical'
          initialValues={initialValues}
          onFinish={(values) => onSubmit(values, attrId, !value.value)}
          form={form}
        >
          <h3 style={{ marginBottom: '30px' }}>Dodaj vrednost</h3>
          <Form.Item
            label='Vrednost'
            rules={[{ required: true, message: 'Please enter value!' }]}
            name={['value', language.default.code]}
          >
            <Input />
          </Form.Item>
          <Divider type='horizontal' />
          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              Potvrdi
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ValueForm;
