import React, { useState, useEffect } from 'react';
import { Tabs, Divider, Input, Button, Form, Row, Col } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';

const { TextArea } = Input;

const formInit = {
  _id: null,
  title: {},
  description: {},
  image: undefined,
};

const TagForm = ({ isNew, data, onSubmit, language, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  let initialValues = { ...formInit, ...data };

  useEffect(() => {
    if (data && data.image) setImage(data.image.url);
  }, [data]);

  const onFinish = async (values, isNew) => {
    onSubmit(values, isNew);
  };

  const imageHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'image' });
  };

  const deleteImageHandler = () => {
    setImage(null);
    form.setFieldsValue({ image: null });
  };

  const onInsertIMage = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item
                  label='Naslov'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas, unesite naslov!',
                    },
                  ]}
                  name={['title', language.default.code]}
                >
                  <Input />
                </Form.Item>

                <Form.Item label='Opis' name={['description', language.default.code]}>
                  <TextArea rows={6} />
                </Form.Item>

                <Divider type='horizontal' />
              </div>
            </Col>

            <Col xs={24} md={8}>
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Fotografija oznake</h4>
                </div>

                <Form.Item name='image' valuePropName='image'>
                  <div className='panel-body'>
                    <UploadBox
                      editHandler={imageHandler}
                      deleteHandler={deleteImageHandler}
                      image={image}
                      index={0}
                      name='image'
                    />
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Dodaj' : 'Izmeni'} oznaku
            </Button>
          </div>
        </Form>

        {modal.visible && (
          <GalleryModal
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/tags/'
            imageType='tag'
            imageHeight={1000}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ ...modal, visible: false })}
            onInsert={(values) => onInsertIMage(values, modal.formKey)}
          />
        )}
      </div>
    </div>
  );
};

export default TagForm;
