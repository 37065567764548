import React, { useState, useEffect } from 'react';
import slugify from 'slugify';
import JoditEditor from 'jodit-react';
import { Tabs, Input, Select, /* DatePicker, */ Button, Form, Row, Col } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';

const { Option } = Select;

const configJodit = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: '300',
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    // 'font',
    'fontsize',
    '\n',
    'brush',
    'paragraph',
    'link',
    'align',
    // '|',
    'undo',
    'redo',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
    'fullsize',
    'print',
    'preview',
    'find',
  ],
};

const formInit = {
  _id: null,
  title: {},
  googleDescription: {},
  keywords: {},
  content: {},
  url: '',
  fullUrl: '',
  status: '',
  image: undefined,
};

const PageForm = ({ isNew, data, onSubmit, language, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  let initialValues = { ...formInit, ...data };

  useEffect(() => {
    if (data && data.image) setImage(data.image.url);
  }, [data]);

  const onFinish = async (values, isNew) => {
    onSubmit(values, isNew);
  };

  const imageHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'image' });
  };

  const deleteImageHandler = () => {
    setImage(null);
    form.setFieldsValue({ image: null });
  };

  const onInsertIMage = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item
                  label='Naslov'
                  rules={[
                    {
                      required: true,
                      message: 'Molim Vas, unesite naslov!',
                    },
                  ]}
                  name={['title', language.default.code]}
                  onChange={(e) => form.setFieldsValue({ url: slugify(e.target.value, { lower: true, strict: true }) })}
                >
                  <Input />
                </Form.Item>

                <Form.Item label='Google opis' name={['googleDescription', language.default.code]}>
                  <Input />
                </Form.Item>

                <Form.Item label='Google ključne reči' name={['keywords', language.default.code]}>
                  <Input />
                </Form.Item>

                <Form.Item label='Sadržaj' name={['content', language.default.code]}>
                  <JoditEditor
                    name={`content[${language.default.code}]`}
                    style={{ margin: '2px 0px', minHeight: '350px' }}
                    config={configJodit}
                  />
                </Form.Item>
                <Form.Item
                  label='Status'
                  name={['status']}
                  rules={[{ required: true, message: 'Molim Vas, izaberite status!' }]}
                >
                  <Select>
                    <Option value='ACTIVE'>Aktivna</Option>
                    <Option value='INACTIVE'>Neaktivna</Option>
                  </Select>
                </Form.Item>

                {/* <Form.Item hidden={true} label='Url' name='url'>
                  <Input disabled />
                </Form.Item> */}
              </div>
            </Col>

            <Col xs={24} md={8}>
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Fotografija stranice</h4>
                </div>

                <Form.Item name='image' valuePropName='image'>
                  <div className='panel-body'>
                    <UploadBox
                      editHandler={imageHandler}
                      deleteHandler={deleteImageHandler}
                      image={image}
                      index={0}
                      name='image'
                    />
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Dodaj' : 'Izmeni'} stranicu
            </Button>
          </div>
        </Form>

        {modal.visible && (
          <GalleryModal
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/pages/'
            imageType='page'
            imageHeight={1000}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ ...modal, visible: false })}
            onInsert={(values) => onInsertIMage(values, modal.formKey)}
          />
        )}
      </div>
    </div>
  );
};

export default PageForm;
