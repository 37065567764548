import React from 'react';
import { Button, Col, Form, Input, Row, Tabs } from 'antd';

const formInit = {
  _id: null,
  title: undefined,
  model: '',
  productName: '',
  manufacturer: '',
  qualityClass: '',
  consumerRights: '',
  countryOfOrigin: '',
  importer: '',
};

const DeclarationForm = ({ isNew, data, language, onSubmit }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data?.item };

  const onFinish = async (values, isNew) => {
    onSubmit(values, isNew);
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Form.Item
                  label='Ime deklaracije'
                  rules={[
                    {
                      required: true,
                      message: 'Molim vas, unesite ime deklaracije!',
                    },
                  ]}
                  name='title'
                >
                  <Input />
                </Form.Item>

                <Form.Item label='Naziv i vrsta robe' name='productName'>
                  <Input />
                </Form.Item>

                {/* <Form.Item label='Proizvođač' name='manufacturer'>
                  <Input />
                </Form.Item> */}

                <Form.Item label='Model' name='model'>
                  <Input />
                </Form.Item>

                {/* <Form.Item label='Klasa kvaliteta' name='qualityClass'>
                  <Input />
                </Form.Item> */}

                <Form.Item label='Prava potrošača' name='consumerRights'>
                  <Input />
                </Form.Item>

                <Form.Item label='Zemlja porekla' name='countryOfOrigin'>
                  <Input />
                </Form.Item>

                {/* <Form.Item label='Uvoznik' name='importer'>
                  <Input />
                </Form.Item> */}
              </div>
            </Col>
          </Row>
          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Dodaj' : 'Izmeni'} deklaraciju
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DeclarationForm;
