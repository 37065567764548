import React, { useEffect, useState } from 'react';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';
import UploadBox from './UploadBox';

const DNDGallery = ({
  imageType,
  form,
  setEnergyImage,
  setGallery,
  editGallerySingleImageTrigger,
  deleteGalleryImageHandler,
}) => {
  const [width, setWidth] = useState();
  const itemList = imageType === 'energyImage' ? form.getFieldValue('energyImage') : form.getFieldValue('gallery');
  const setItemList = imageType === 'energyImage' ? setEnergyImage : setGallery;

  useEffect(() => {
    const dndDropzone = document.getElementById('dnd-dropzone');
    setWidth(dndDropzone.offsetWidth);
    window.addEventListener('resize', () => setWidth(dndDropzone.offsetWidth));
    return () => window.removeEventListener('resize', () => setWidth(dndDropzone.offsetWidth));
  }, []);

  // dnd -> reordering the images
  const onChange = (sourceId, sourceIndex, targetIndex) => {
    const newList = swap(itemList, sourceIndex, targetIndex);
    form.setFieldsValue({ [imageType]: newList });
    setItemList(newList);
  };

  const rows = itemList ? Math.ceil(itemList.length / Math.floor(width / 105)) : 1;

  return (
    <GridContextProvider onChange={onChange}>
      <div
        id='dnd-dropzone'
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          touchAction: 'none',
          margin: '8px 0',
        }}
      >
        <GridDropZone
          style={{
            flex: 1,
            height: `${rows * 114}px`,
            border: '1px solid rgba(0, 0, 0, 0.1)',
            background: 'rgba(0, 0, 0, 0.1)',
            borderRadius: '3px',
            padding: '0 4px',
          }}
          id='dropzone'
          boxesPerRow={Math.floor(width / 105)}
          rowHeight={115}
        >
          {itemList.map((item, index) => (
            <GridItem key={item._id}>
              <div
                style={{
                  touchAction: 'none',
                  margin: '5px 0',
                }}
              >
                <UploadBox
                  editHandler={() => editGallerySingleImageTrigger(index, item._id)}
                  deleteHandler={() => deleteGalleryImageHandler(item._id)}
                  image={item.url}
                  index={index}
                  name={imageType}
                />
              </div>
            </GridItem>
          ))}
        </GridDropZone>
      </div>
    </GridContextProvider>
  );
};

export default DNDGallery;
