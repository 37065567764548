import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, notification, Modal, Form, Input, Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
//import { GalleryTable } from '../../components/tables';
import { Row, Col } from 'antd';
import Axios from 'axios';
import BannersImage from '../../components/base/BannersImage';

const { TextArea } = Input;

// const TABLE_COLUMN_KEYS = ['_id', '__v', 'key', 'updatedAt', 'uploadedBy', 'url', 'forMobile', 'localPath'];

const Banners = (props) => {
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [altTitle, setAltTitle] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [isVisible, setVisibility] = useState(false);
  const [forMobile, setForMobile] = useState(false);
  const [bannerUrl, setBannerUrl] = useState('');

  const [galleryForDelete, setGalleryForDelete] = useState([]);
  const [selected, setSelected] = useState([]);
  const [currentSelected] = useState([]); // izbrisano setCurrentSelected
  const [images, setImages] = useState(null);
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1, id: null });
  const [allSelected, setAllSelected] = useState(false);
  const [search, setSearch] = useState('');
  //const [visible, setVisible] = useState(false);
  const { id } = props.match.params;

  useEffect(() => {
    try {
      const fetchImages = async () => {
        const imagesRes = await Axios.get(`${SERVER_URL}/banners`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        setImages(imagesRes);
      };
      fetchImages();
    } catch (error) {
      console.log(error);
    }
    //setModal((modal) => ({ ...modal, limit: 20 })); // setting to 20 for testing selectHandler
  }, [fetchTrigger, id, title, currentuser.data.token]);

  console.log('images', images);

  const editImageTrigger = (id, title, description, altTitle, isVisible, forMobile, bannerUrl) => {
    setTitle(title);
    setDescription(description);
    setAltTitle(altTitle);
    setVisibility(isVisible);
    setForMobile(forMobile);
    setBannerUrl(bannerUrl);
    setModal({ ...modal, visible: true, formKey: 'featureImage', id: id });
  };

  const handleVisibility = (event) => {
    setVisibility(event.target.checked);

    console.log(isVisible);
  };

  const handleMobileVersion = (event) => {
    setForMobile(event.target.checked);
  };

  const selectHandler = (id) => {
    let arr = [];
    if (selected.includes(id)) {
      const removed = selected.filter((item) => item !== id);
      setSelected(removed);
      setGalleryForDelete(removed);
      setAllSelected(false);
    }
    if (!selected.includes(id)) {
      setSelected([...selected, id]);
      arr.push(id);
      setGalleryForDelete([...selected, arr]);
      setAllSelected(false);
    }
  };

  // const selectAll = () => {
  //   let arr = [];
  //   images.data.map((item) => {
  //     arr.push(item);
  //   });
  //   setAllSelected(!allSelected);
  //   if (!allSelected) {
  //     setSelected(arr);
  //     setGalleryForDelete(arr);
  //   } else {
  //     setSelected([]);
  //     setGalleryForDelete([]);
  //   }
  // };

  // LOGIKA ZA BRISANJE SVIH SLIKA IZ BAZE I IZ s3 NIJE DOVRSENA
  const bannersDelete = async () => {
    if (galleryForDelete.length > 0) {
      const selectedImages = { images: galleryForDelete };

      try {
        await Axios.post(`${SERVER_URL}/banners-delete`, selectedImages, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        setGalleryForDelete([]);
        notification.success({
          message: 'Banner deleted.',
          placement: 'bottomRight',
        });
        setFetchTrigger(!fetchTrigger);
      } catch (err) {
        notification.error({
          message: 'Problem with deleting banner. Please try later.',
          placement: 'bottomRight',
        });
      }
    }
  };

  // On click push id of the image
  const deleteBucket = (id) => {
    let arr = [];
    arr.push(id);
    setGalleryForDelete(arr);
  };

  // DELETE one image
  const deleteOneImage = async (id) => {
    deleteBucket(id);
    const selectedImages = { images: galleryForDelete };

    try {
      await Axios.post(`${SERVER_URL}/banners-delete/${id}`, selectedImages, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setGalleryForDelete([]);
      notification.success({
        message: 'Banner deleted.',
        placement: 'bottomRight',
      });
      setFetchTrigger(!fetchTrigger);
    } catch (err) {
      notification.error({
        message: 'Problem with deleting the banner. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  // onEdit
  const okHandle = async (id) => {
    console.log('ISVISIBLE', isVisible);
    const update = {
      updatedImage: {
        title,
        description,
        altTitle,
        isVisible,
        forMobile,
        bannerUrl,
      },
    };
    try {
      await Axios.put(`${SERVER_URL}/banners-update/${id}`, update.updatedImage, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setModal({ visible: false });
      notification.success({
        message: 'Banner edited.',
        placement: 'bottomRight',
      });
      setFetchTrigger(!fetchTrigger);
    } catch (error) {
      notification.error({
        message: 'Problem with editing the banner. Please try later.',
        placement: 'bottomRight',
      });
    }
  };
  return (
    // edit-panel fiksiran na 92vh zbog scroll-a unutar njega
    <div className='edit-panel' style={{ height: '92vh' }}>
      <div className='actions-block'>
        <div className='left-side'>
          <Link to='/admin/banners/new-content'>
            <Button type='primary'>Dodaj novi sadržaj</Button>
          </Link>
          <Button style={{ marginLeft: '8px' }} onClick={bannersDelete} type='primary'>
            Izbriši odabrano
          </Button>
        </div>

        <div className='right-side'>
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ width: '100%' }}
            className='search'
            placeholder='Traži po nazivu fajla'
          />
        </div>

        {/* <Button type='primary' style={{ marginLeft: '8px' }} onClick={selectAll}>
          {!allSelected ? 'Select all' : 'Deselect all'}
        </Button> */}
      </div>

      <div className='content' style={{ padding: '15px' }}>
        {/* SEARCH */}
        {images && search && images?.data && (
          <Row gutter={[8, 8]}>
            {images.data
              .filter((image) => image.originalname.toLowerCase().includes(search.toLowerCase()))
              .map((image) => {
                return (
                  <Col xs={12} sm={8} md={6} lg={4} xl={3} xxl={2} key={image._id}>
                    {image.isVisible && (
                      <BannersImage
                        image={image}
                        name={image.originalname}
                        editHandler={editImageTrigger}
                        limit={modal.limit}
                        formKey={modal.formKey}
                        imageIndex={modal.index}
                        imageId={modal.id}
                        selected={selected}
                        selectHandler={selectHandler}
                        currentSelected={currentSelected}
                        //allSelected={allSelected}
                        deleteHandler={deleteOneImage}
                      />
                    )}
                  </Col>
                );
              })}
          </Row>
        )}

        {/* MAIN GRID */}
        {images && images.isLoading && (
          <LoadingOutlined
            spin
            style={{
              fontSize: '3rem',
              marginTop: '5rem',
              marginLeft: '50%',
              transform: 'translateX(-50%)',
            }}
          />
        )}

        {images && !images.isLoading && images.isError && (
          <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>
        )}

        {images && !images.isLoading && !images.isError && images.data && !search && images.data.length > 0 && (
          // scrollujuci div u gallery
          <div style={{ height: '75vh', overflowY: 'scroll', overflowX: 'hidden' }}>
            <Row gutter={[8, 8]}>
              {images.data.map((image) => {
                return (
                  <Col xs={12} sm={8} md={6} lg={4} xl={3} xxl={2} key={image._id}>
                    <BannersImage
                      image={image}
                      name={image.originalname}
                      editHandler={editImageTrigger}
                      limit={modal.limit}
                      formKey={modal.formKey}
                      imageIndex={modal.index}
                      imageId={modal.id}
                      selected={selected}
                      selectHandler={selectHandler}
                      currentSelected={currentSelected}
                      allSelected={allSelected}
                      deleteHandler={deleteOneImage}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        )}

        {images && images.data && !images.isLoading && images.data.length === 0 && (
          // <h2 style={{ marginTop: '3rem', textAlign: 'center' }}>NO IMAGES</h2>
          <div className='no-data-box'>
            <h2 style={{ textAlign: 'center' }}>Nema podataka</h2>
          </div>
        )}
      </div>

      <Modal
        id={modal.id}
        centered
        visible={modal.visible}
        okText='SAČUVAJ'
        cancelText='ODUSTANI'
        formKey={modal.formKey}
        title={<strong>IZMENI BANER</strong>}
        onCancel={() => setModal({ visible: false })}
        onOk={() => okHandle(modal.id)}
      >
        <Form className='form-horizontal' layout='vertical' form={form}>
          <Form.Item label='Naziv'>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} name='title' />
          </Form.Item>
          <Form.Item style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {console.log(isVisible)}
              <span style={{ marginRight: '8px' }}>Vidljivo</span>
              <Input type='checkbox' checked={isVisible} onChange={handleVisibility} />
            </div>
          </Form.Item>

          <Form.Item style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '8px' }}>Verzija za mobilni</span>
              <Input type='checkbox' checked={forMobile} onChange={handleMobileVersion} />
            </div>
          </Form.Item>

          <Form.Item label='Opis' onChange={(e) => setDescription(e.target.value)}>
            <TextArea rows={4} name='description' value={description} />
          </Form.Item>

          <Form.Item label='URL za button na baneru' onChange={(e) => setBannerUrl(e.target.value)}>
            <Input
              name='altTitle'
              value={bannerUrl}
              prefix='https://www.najcena/'
              suffix='.rs'
              style={{ paddingLeft: '10px' }}
            />
          </Form.Item>

          <Form.Item label='Alternativni naslov' onChange={(e) => setAltTitle(e.target.value)}>
            <Input name='altTitle' value={altTitle} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Banners;
