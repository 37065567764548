import React from 'react';
import { Button, Form, Row, Col, Select, Typography, Card, Table } from 'antd';
const { Option } = Select;
const { Text } = Typography;
const formInit = {
  _id: null,
  orderNumber: null,
  deliveryDetails: {},
  totalPrice: null,
  status: '',
  order: [],
};

const OrderForm = ({ data, onSubmit, createInvoice }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  const orderStatus = ['Na čekanju', 'Isporučeno', 'Nije isporučeno'];
  console.log('data111', data);
  const onFinish = async (values) => {
    onSubmit(values);
  };
  const columns = [
    {
      key: '1',
      title: 'Proizvod',
      dataIndex: 'productName',
    },
    {
      key: '2',
      title: 'Kategorija',
      dataIndex: 'productCategory',
    },
    {
      key: '3',
      title: 'Cena',
      dataIndex: 'productPrice',
    },
    {
      key: '4',
      title: 'Cena sa popustom',
      dataIndex: 'productDiscountPrice',
    },
    {
      key: '5',
      title: 'Količina',
      dataIndex: 'quantity',
    },
  ];
  let fullName = '';
  if (data.deliveryDetails) {
    fullName += data.deliveryDetails.ime ? data.deliveryDetails.ime + ' ' : '';
    fullName += data.deliveryDetails.prezime ? data.deliveryDetails.prezime : '';
  }

  const productData = data?.order?.map((item) => {
    return {
      productName: item.productName,
      productCategory: item.productCategory,
      productPrice: item.productPrice,
      productDiscountPrice: item.productDiscountPrice,
      quantity: item.quantity,
    };
  });

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Card title='Detalji o porudžbini' bordered={false}>
                  <p>
                    <Text strong>Broj porudžbine: </Text>
                    {data.orderNumber ? data.orderNumber : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Kupac: </Text>
                    {fullName !== '' ? fullName : `${data.deliveryDetails.ime} ${data.deliveryDetails.prezime}`}
                  </p>
                  <p>
                    <Text strong>Ulica: </Text>
                    {data.deliveryDetails && data.deliveryDetails.ulica
                      ? data.deliveryDetails.ulica
                      : data.deliveryDetails.ulica}
                  </p>
                  <p>
                    <Text strong>Kućni broj: </Text>
                    {data.deliveryDetails && data.deliveryDetails.kucnibroj
                      ? data.deliveryDetails.kucnibroj
                      : data.deliveryDetails.kucnibroj}
                  </p>
                  <p>
                    <Text strong>Broj stana: </Text>
                    {data.deliveryDetails && data.deliveryDetails.brojstana
                      ? data.deliveryDetails.brojstana
                      : data.deliveryDetails.brojstana}
                  </p>
                  <p>
                    <Text strong>Grad: </Text>
                    {data.deliveryDetails && data.deliveryDetails.grad
                      ? data.deliveryDetails.grad
                      : data.deliveryDetails.grad}
                  </p>
                  <p>
                    <Text strong>Zip: </Text>
                    {data.deliveryDetails && data.deliveryDetails.zipcode
                      ? data.deliveryDetails.zipcode
                      : data.deliveryDetails.zipcode}
                  </p>
                  <p>
                    <Text strong>Broj telefona: </Text>
                    {data.deliveryDetails && data.deliveryDetails.brojtelefona
                      ? data.deliveryDetails.brojtelefona
                      : data.deliveryDetails.brojtelefona}
                  </p>
                  <p>
                    <Text strong>Email: </Text>
                    {data.deliveryDetails && data.deliveryDetails.email
                      ? data.deliveryDetails.email
                      : data.deliveryDetails.email}
                  </p>
                  <p>
                    <Text strong>Cena ukupno: </Text>
                    {data.totalAmount ? data.totalAmount : 'Nema podataka'}
                  </p>
                </Card>
                <Form.Item label='Status porudžbine' name='status'>
                  <Select>
                    {orderStatus.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <div className='text-right'>
                  <Button type='primary' htmlType='submit'>
                    Osveži
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Table
                  pagination={false}
                  // rowKey='_id'
                  style={{ margin: '0px 0px 40px 0px' }}
                  columns={columns}
                  dataSource={productData}
                  bordered
                />
                {/* <div className='text-right'>
                  <Button type='primary' onClick={createInvoice}>
                    Create Invoice
                  </Button>
                </div> */}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default OrderForm;
