import React from 'react';
import { Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import SortableTree from 'react-sortable-tree';
import { SERVER_URL } from '../../config';
import 'react-sortable-tree/style.css';

const CategoryTree = ({ treeData, setTreeData, editHandler, deleteHandler }) => {
  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  return (
    <div>
      {treeData.length > 0 && (
        <div style={{ height: '500px' }}>
          <SortableTree
            canDrag={false}
            treeData={treeData ? treeData : []}
            onChange={(data) => setTreeData(data)}
            onMoveNode={(data) => console.log('OnMove', data)}
            generateNodeProps={(rowInfo) => {
              console.log('rowInfo', rowInfo);
              return {
                buttons: [
                  <button
                    type='button'
                    className='custom-button'
                    key={rowInfo.node._id + 'add'}
                    title='Add'
                    onClick={() => editHandler(false, rowInfo.node._id, rowInfo.parentNode)}
                  >
                    <PlusOutlined />
                  </button>,
                  <button
                    type='button'
                    className='custom-button'
                    key={rowInfo.node._id + 'edit'}
                    title='Edit'
                    onClick={() => editHandler(rowInfo.node._id, false, rowInfo.parentNode)}
                  >
                    <EditOutlined />
                  </button>,
                  <Popconfirm
                    className='custom-button'
                    placement='left'
                    title={`Ovo će obrisati ${rowInfo.node.title}`}
                    onConfirm={() => {
                      deleteHandler(rowInfo.node._id);
                    }}
                    okText='Ok'
                    cancelText='Odustani'
                  >
                    <DeleteOutlined />
                  </Popconfirm>,
                  <div key={rowInfo.node._id + '_image'}>
                    {rowInfo.node.image && rowInfo.node.image.url ? (
                      <img
                        style={{ borderRadius: '7px', width: 50, height: 50 }}
                        src={getUrl(rowInfo.node.image.url)}
                        alt='category'
                      />
                    ) : null}
                  </div>,
                ],
              };
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CategoryTree;
